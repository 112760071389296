<template>
  <div>
    <div v-show="viewMode==='grid'">
      <grid-toolbar
        :ref="gridToolbarName"
        :page-name="pageName"
        :grid-ref-name="gridName"
        :selected-rows-data="selectedRowsData"
        :selected-rows-dropdown-visible="false"
        :title="title">
        <template slot="toolbarSlotBefore">
          <b-button-group
            v-if="gridConfig!==null && gridConfig.actionPageBtn!==undefined"
            size="sm"
          >
            <div
              v-for="(btn, index) in gridConfig.actionPageBtn"
              :key="index">
              <b-button
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'booking'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :title="btn.title"
                variant="primary"
                size="sm"
                style="margin-right: 2px"
                @click="actionPageClicked(btn.command)"
              >
                <feather-icon
                  :icon="btn.icon"
                  size="12"/>
              </b-button>
            </div>
          </b-button-group>
        </template>
        <template
          v-if="gridConfig!==null && gridConfig.actionMultipleRowBtn!==undefined"
          slot="selectedRowsCommandItem">
          <div
            v-for="(btn, index) in gridConfig.actionMultipleRowBtn"
            :key="index">
            <b-dropdown-item
              v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'booking'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
              @click="selectedRowsCommand(btn.command)">
              <span class="text-uppercase">{{ btn.label }}</span>
            </b-dropdown-item>
          </div>
        </template>
      </grid-toolbar>

      <dx-data-grid
        :ref="gridName"
        :on-content-ready="gridContentReady"
        :focused-column-index.sync="focusedRowIndex"
        :on-key-down="gridKeyDown"
        :on-focused-row-changing="gridFocusedRowChanging"
        :on-focused-row-changed="gridFocusedRowChanged"
        :on-focused-cell-changed="gridFocusedCellChanged"
        :on-focused-cell-changing="gridFocusedCellChanging"
        :on-selection-changed="selectionChanged"
        :height="gridHeight">
        <div
          slot="actionTpl"
          slot-scope="data">
          <b-dropdown
            :ref="gridName+'RowAction'+data.data.data.id"
            variant="link"
            no-caret
            size="sm">
            <template #button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body p-0"
              />
            </template>
            <div
              v-for="(btn, index) in gridConfig.actionRowBtn"
              :key="index">
              <b-dropdown-item
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'booking'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                class=""
                @click="actionRowClicked({mode:btn.command, rowData:data.data.data})">
                <feather-icon :icon="btn.icon"/>
                <span class="align-middle ml-50 text-uppercase">{{ btn.label }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <div
          slot="hasChildrenTpl"
          slot-scope="data">
          <b-badge
            v-if="data.data.row.data.aktif"
            variant="success">
            YES
          </b-badge>
          <b-badge
            v-else
            variant="danger">
            NO
          </b-badge>
        </div>

        <template #detailTemplate="{ data: booking }">
          <DxTabPanel class="booking-detail">
            <DxItem
              title="Services"
              template="services-tab"
            />
            <template #services-tab>
              <DxDataGrid
                id="booking-detail-grid-container"
                :data-source="booking.data.booking_detail"
                key-expr="id"
              >
                <DxColumn
                  :min-width="200"
                  data-field="service.name"
                  caption="Service Name"
                />
                <DxColumn
                  :width="150"
                  data-field="category_name"
                  caption="Category"
                />
                <DxColumn
                  :width="100"
                  data-field="person"
                  caption="Person"
                />
                <DxColumn
                  :width="200"
                  data-field="service.coach.fullname"
                  caption="Coach"
                />
                <DxColumn
                  :width="200"
                  data-field="schedule_date_formatted"
                  caption="Date"
                />
                <DxColumn
                  :width="100"
                  data-field="slot_start_formatted"
                  caption="Start"
                />
                <DxColumn
                  :width="100"
                  data-field="slot_end_formatted"
                  caption="End"
                />
              </DxDataGrid>
            </template>
            <DxItem
              title="Billing"
              template="billing-tab"
            />
            <template #billing-tab>
              <DxForm
                :form-data="booking.data.user"
                :items="billingItems"
                :col-count="1"
                :customize-item="customizebillingItems"
                class="address-form form-container"
              >
                <template #form-item="{ data: item }">
                  <span>
                    {{ item.editorOptions.value }}
                  </span>
                </template>
              </DxForm>
            </template>
          </DxTabPanel>
        </template>

        <dx-column-chooser mode="select"/>
      </dx-data-grid>
    </div>
    <div
      v-if="viewMode==='form'"
      class="mb-3 booking-form">
      <form-app
        :ref="formName"
        :form-data-id="formDataId"
        :title="title"
        :form-name="formName"
        :on-form-close="formOnHide"
        :on-form-add-new="formOnAddNew"
        :form-clone-mode="formCloneMode"
        :on-form-field-data-changed="onFormFieldDataChanged"/>
    </div>
    <audit-grid
      v-if="viewMode==='audit'"
      :ref="auditGridName"
      :title="title"
      :name="auditGridName"
      :hide-event="formOnHide"/>
  </div>
</template>

<script>
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel'
// import { DxFileUploader } from 'devextreme-vue/ui/file-uploader'
// import { DxTileView } from 'devextreme-vue/ui/tile-view'
import { DefaultPageData } from '@/modules/mixins/data'
import { nextTick } from '@vue/composition-api'

export default {
  components: {
    DxTabPanel,
    DxItem
    // DxFileUploader,
    // DxTileView
  },
  props: {
    pageName: {
      type: String,
      default: 'Bookings'
    },
    title: {
      type: String,
      default: 'Bookings'
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 198
      }
    }
  },
  data() {
    const data = { ...DefaultPageData }
    this.$stateMerge(data, {
      viewMode: 'grid',
      gridName: `${this.pageName}Grid`,
      gridToolbarName: `${this.pageName}GridToolbar`,
      actionRowButtonName: `${this.pageName}GridActionRowBtn`,
      auditGridName: `${this.pageName}GridAudit`,
      formName: `${this.pageName}Form`,
      formOnHide: `${this.pageName}FormHideEvent`,
      formOnAddNew: `${this.pageName}FormAddNewEvent`,
      delRowsRoute: 'bookingDelBatch',
      restoreRowsRoute: 'bookingRestoreBatch',

      billingItems: [
        {
          label: {
            text: 'Fullname'
          },
          dataField: 'member.fullname'
        },
        {
          label: {
            text: 'Email'
          },
          dataField: 'email'
        },
        {
          label: {
            text: 'Address'
          },
          dataField: 'member.address'
        }
      ]
    })
    return data
  },
  created() {
    const vm = this
    vm.gridCreated()
  },
  mounted() {
    const vm = this
    vm.gridMount()
    vm.formMount()
  },
  methods: {
    bindMoustrap() {

    },
    actionPageClicked(command) {
      if (command === 'addNew') {
        this.viewMode = 'form'
      }
    },
    actionRowClicked(command) {
      const vm = this
      console.log('actionRowClicked', command)
      setTimeout(() => {
        if (command.mode === 'editRow' || command.mode === 'cloneRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          // eslint-disable-next-line radix
          vm.formDataId = command.rowData.id
          vm.formCloneMode = command.mode === 'cloneRow'
          vm.viewMode = 'form'
        } else if (command.mode === 'deleteRow') {
          vm.delRows([command.rowData.id], false)
        } else if (command.mode === 'restoreRow') {
          vm.restoreRows([command.rowData.id], false)
        } else if (command.mode === 'auditRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          const route = `api/booking/${command.rowData.id}/audit`
          vm.viewMode = 'audit'
          nextTick().then(() => {
            vm.$refs[vm.auditGridName].updateRoute(route)
          })
        } else {
          vm.msgShow('Unauthorized access', 'error')
        }
      })
    },
    selectedRowsCommand(command) {
      if (command === 'deleteRow') {
        const vm = this
        setTimeout(() => {
          vm.delRows(this.$_map(this.selectedRowsData, 'id'))
          vm.$refs[vm.gridToolbarName].$refs[`${vm.gridName}SelectedRowsButton`].hide()
        }, 200)
      }
    },
    onFormFieldDataChanged(e) {
      const vm = this.$refs[this.formName]
      if (e.value === undefined || !vm.formItemsSetStatus) {
        return false
      }
    },
    formMount() {
      this.$events.$on(`${this.formName}FormMountedEvent`, () => {
        console.log(`${this.formName}FormMountedEvent`)
        this.additionalFormVisible = true

        const vm = this.$refs[this.formName]
        const { formDataEdit } = vm
        if (formDataEdit !== undefined && formDataEdit !== null) {
          console.log('formDataEdit')
        }
      })
    },
    customizebillingItems(item) {
      item.template = 'form-item'
    }
  }
}
</script>

<style>
.dx-tile-content {
  height: 100%;
  padding: 0;
  position: relative;
}
.dx-fileuploader-input {
  opacity: 0;
}
.dx-fileuploader-input-wrapper {
  display: flex;
}
.booking-form .dx-form-group-content {
  padding-top: 10px !important;
}
.dx-datagrid-checkbox-size .dx-checkbox-icon {
  vertical-align: top;
}
.booking-detail .dx-tab {
  padding: 3px 5px;
}
</style>
